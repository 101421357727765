import { Button, Modal, Select, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useEffect } from "react";
import { mutate } from "swr";
import * as Yup from "yup";
import api from "../../../../api";
import { showErrorToast, showSuccessToast } from "../../../../utils/toasts";

interface UsersModalProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  user: any;
  setUser: (user: any) => void;
}

const UsersModal = (props: UsersModalProps) => {
  const { opened, setOpened, user, setUser } = props;

  useEffect(() => {
    if (!opened) {
      setUser(null);
      form.reset();
    } else if (opened && user) {
      form.setValues(user);
    }
  }, [opened]);

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      role: "user", // volunteer, volunteers_manager, stats
    },
    validate: yupResolver(user ? validateUpdateSchema : validateCreateSchema),
  });

  const create = () => {
    api
      .post("/users", form.values)
      .then((data) => {
        if (data.data.ok) {
          setOpened(false);
          form.reset();
          mutate("/users");
          showSuccessToast({
            title: "Usuario creado",
            message: "El usuario se ha creado correctamente",
          });
        }
      })
      .catch((err) => {
        showErrorToast({
          title: "Error al crear el usuario",
          message: err.response.data.message,
        });
      });
  };

  const update = () => {
    api
      .put(`/users/${user.id}`, form.values)
      .then((data) => {
        if (data.data.ok) {
          setOpened(false);
          form.reset();
          mutate("/users");
          showSuccessToast({
            title: "Usuario actualizado",
            message: "El usuario se ha actualizado correctamente",
          });
        }
      })
      .catch((err) => {
        showErrorToast({
          title: "Error al actualizar el usuario",
          message: err.response.data.message,
        });
      });
  };

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title={user ? "Editar usuario" : "Crear usuario"}
    >
      <form onSubmit={form.onSubmit((values) => (user ? update() : create()))}>
        <TextInput
          label="Nombre"
          placeholder="Nombre"
          required
          {...form.getInputProps("name")}
        />
        <TextInput
          label="Email"
          placeholder="Email"
          required
          mt="sm"
          {...form.getInputProps("email")}
        />
        <TextInput
          name="password"
          label="Contraseña"
          placeholder="Contraseña"
          type={"password"}
          required={!user}
          mt="sm"
          {...form.getInputProps("password")}
        />
        <TextInput
          label="Confirmar contraseña"
          placeholder="Confirmar contraseña"
          type={"password"}
          required={!user}
          mt="sm"
          {...form.getInputProps("password_confirmation")}
        />

        <Select
          label="Rol"
          placeholder="Rol"
          required
          mt="sm"
          {...form.getInputProps("role")}
          data={[
            { label: "Usuario", value: "user" },
            { label: "Voluntario", value: "volunteer" },
            { label: "Gestor de voluntarios", value: "volunteers_manager" },
            { label: "Estadísticas", value: "stats" },
          ]}
        />

        <Button type="submit" fullWidth mt="sm">
          {user ? "Actualizar" : "Crear"} usuario
        </Button>
      </form>
    </Modal>
  );
};

export default UsersModal;

const validateCreateSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es obligatorio"),
  email: Yup.string()
    .email("El email no es válido")
    .required("El email es obligatorio"),
  password: Yup.string()
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .required("La contraseña es obligatoria"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden")
    .required("La confirmación de la contraseña es obligatoria"),
  role: Yup.string()
    .required("El rol es obligatorio")
    .oneOf(["user", "volunteer", "volunteers_manager", "stats"]),
});

const validateUpdateSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es obligatorio"),
  email: Yup.string()
    .email("El email no es válido")
    .required("El email es obligatorio"),
  role: Yup.string()
    .required("El rol es obligatorio")
    .oneOf(["user", "volunteer", "volunteers_manager", "stats"]),
  password: Yup.string().nullable(),
  password_confirmation: Yup.string().nullable(),
});
