import { Progress, Tooltip } from "@mantine/core";
import { TableCard } from "@3t/utils";

interface PositionsTableProps {
  filterData: () => any[];
  activeMode: number;
  setPositionsModal: (opened: boolean) => void;
  setSelectedPosition: (position: any) => void;
  setVolunteersModal: (opened: boolean) => void;
}

const columns = [
  {
    Header: "Sector",
    accessor: "zone.name",
  },
  {
    Header: "Código",
    accessor: "code",
  },
  {
    Header: "Tipo",
    accessor: "type",
  },
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "Voluntarios",

    Cell: (row: any) => {
      const data = row.row.original;
      return (
        <Tooltip
          label={` ${data.volunteers.length} / ${data.volunteers_needed} 
            `}
        >
          <Progress
            value={(data.volunteers.length / data.volunteers_needed) * 100}
            animate
          />
        </Tooltip>
      );
    },
  },
];

const PositionsTable = (props: PositionsTableProps) => {
  const {
    filterData,
    setPositionsModal,
    setSelectedPosition,
    setVolunteersModal,
    activeMode,
  } = props;

  const handleRowClick = (row: any) => {
    if (activeMode === 0) {
      setSelectedPosition(row);
      setVolunteersModal(true);
    } else if (activeMode === 2) {
      setSelectedPosition(row);
      setPositionsModal(true);
    }
  };
  return (
    <TableCard
      columns={columns}
      data={filterData()}
      enableSearch={false}
      title="Listado de puestos"
      onRowClick={handleRowClick}
      header={<></>}
    />
  );
};

export default PositionsTable;
