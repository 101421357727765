import { Drawer, MultiSelect } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import useSWR from "swr";
import SWRrender from "../../../../components/SWRrender";

interface PositionsFilterDrawerProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  filters: any;
  setFilters: (filters: any) => void;
}

const PositionsFilterDrawer = (props: PositionsFilterDrawerProps) => {
  const { opened, setOpened, filters, setFilters } = props;

  const filtersForm = useForm({
    initialValues: {
      zones: [],
      types: [],
      volunteers: [],
    },
  });

  useEffect(() => {
    setFilters(filtersForm.values);
  }, [filtersForm.values]);

  return (
    <Drawer
      title={"Filtrar puestos"}
      position="right"
      opened={opened}
      padding="xl"
      onClose={() => setOpened(false)}
    >
      <SWRrender>
        <ZonesSelect form={filtersForm} />
      </SWRrender>
      <MultiSelect
        label="Tipo de puesto"
        mt="sm"
        searchable
        placeholder="Tipo de puesto"
        data={[
          { label: "Tráfico", value: "traffic" },
          { label: "Seguridad", value: "security" },
          { label: "Avituallamientos", value: "refreshments" },
          { label: "Cronometraje", value: "timing" },
        ]}
        {...filtersForm.getInputProps("types")}
      />

      <SWRrender>
        <VolunteersSelect form={filtersForm} />
      </SWRrender>
    </Drawer>
  );
};

const ZonesSelect = (props: any) => {
  const { form } = props;
  const { data } = useSWR("/zones", { suspense: true });

  return (
    <MultiSelect
      label="Sector"
      placeholder="Sector"
      searchable
      data={data.zones.map((zone: any) => {
        return { label: zone.name, value: String(zone.id) };
      })}
      {...form.getInputProps("zones")}
    />
  );
};

const VolunteersSelect = (props: any) => {
  const { form } = props;
  const { data } = useSWR("/volunteers", { suspense: true });

  return (
    <MultiSelect
      label="Voluntario"
      placeholder="Voluntario"
      searchable
      mt="sm"
      data={data.volunteers.map((volunteer: any) => {
        return { label: volunteer.name, value: String(volunteer.id) };
      })}
      {...form.getInputProps("volunteers")}
    />
  );
};
export default PositionsFilterDrawer;
