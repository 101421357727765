import { TableCard } from "@3t/utils";
import {
  ActionIcon,
  Button,
  Center,
  Divider,
  Modal,
  Select,
} from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useState } from "react";
import useSWR, { mutate } from "swr";
import api from "../../../../api";
import SWRrender from "../../../../components/SWRrender";
import { handleResponseError } from "../../../../utils/error";
import { showSuccessToast } from "../../../../utils/toasts";
import { preparePositionDates } from "../PositionsFormModal/PositionsFormModal";
import { useMediaQuery } from "@mantine/hooks";
import { IconTrash } from "@tabler/icons";

interface PositionsVolunteersModalProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  position: any;
}

const PositionsVolunteersModal = (props: PositionsVolunteersModalProps) => {
  const { opened, setOpened, position } = props;

  const columns = [
    {
      Header: "Nombre",
      accessor: "name",
      Cell: (row: any) => {
        const data = row.row.original;
        return (
          <div>
            {data.name} {data.surname}
          </div>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "",
      accessor: "a",
      Cell: (row: any) => {
        return (
          <ActionIcon
            color="red"
            onClick={() => {
              unassignVolunteer(row.row.original);
            }}
          >
            <IconTrash size={16} />
          </ActionIcon>
        );
      },
    },
  ];

  const isPC = useMediaQuery("(min-width: 900px)");

  const unassignVolunteer = (volunteer: any) => {
    api
      .delete("/positions/" + position.id + "/volunteer/" + volunteer.id)
      .then((data) => {
        if (data.data.ok) {
          showSuccessToast({
            title: "Voluntario desasignado",
            message: "El voluntario se ha desasignado correctamente",
          });

          mutate("/positions");

          setOpened(false);
        }
      })
      .catch((err) => {
        handleResponseError(err);
      });
  };

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="Voluntarios asignados"
      size={!isPC ? "sm" : "auto"}
    >
      <AssignVolunteerForm position={position} setOpened={setOpened} />
      <Divider mt="md" />

      <TableCard
        columns={columns}
        data={position ? position?.volunteers : []}
        enableSearch={false}
        card={false}
      />
    </Modal>
  );
};

const AssignVolunteerForm = (props: any) => {
  const [showForm, setShowForm] = useState(false);

  const assignForm = useForm({
    initialValues: {
      volunteer: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
    },
  });

  if (!showForm) {
    return (
      <Center>
        <Button onClick={() => setShowForm(true)}> Asignar voluntario </Button>
      </Center>
    );
  }

  return (
    <>
      <form
        onSubmit={assignForm.onSubmit((values) => {
          api
            .post(
              "/positions/" +
                props.position.id +
                "/volunteer/" +
                values.volunteer,
              preparePositionDates(values)
            )
            .then((data) => {
              if (data.data.ok) {
                showSuccessToast({
                  title: "Voluntario asignado",
                  message: "El voluntario se ha asignado correctamente",
                });

                mutate("/positions");

                props.setOpened(false);
                setShowForm(false);
                assignForm.reset();
              }
            })
            .catch((err) => {
              handleResponseError(err);
            });
        })}
      >
        <SWRrender>
          <GenerateVolunteersField
            form={assignForm}
            volunteers={props.position.volunteers}
          />
        </SWRrender>
        <DatePicker
          label="Fecha de inicio"
          placeholder="Fecha de inicio"
          {...assignForm.getInputProps("startDate")}
          mt="sm"
        />

        {assignForm.values.startDate && (
          <TimeInput
            label="Hora de inicio"
            placeholder="Hora de inicio"
            {...assignForm.getInputProps("startTime")}
            mt="sm"
          />
        )}

        <DatePicker
          label="Fecha de fin"
          placeholder="Fecha de fin"
          mt="sm"
          {...assignForm.getInputProps("endDate")}
        />

        {assignForm.values.endDate && (
          <TimeInput
            label="Hora de fin"
            placeholder="Hora de fin"
            {...assignForm.getInputProps("endTime")}
            mt="sm"
          />
        )}

        <Button type="submit" fullWidth mt="sm">
          Asignar voluntario
        </Button>
      </form>
    </>
  );
};

const GenerateVolunteersField = ({ form, volunteers }: any) => {
  const { data } = useSWR("/volunteers", { suspense: true });

  const volunteersOptions = () => {
    const result: any = [];

    data.volunteers.forEach((volunteer: any) => {
      if (!volunteers.find((v: any) => v.id === volunteer.id)) {
        result.push({
          label: volunteer.name + " " + volunteer.surname,
          value: volunteer.id,
        });
      }
    });

    return result;
  };
  return (
    <Select
      label="Voluntario a asignar"
      mt="sm"
      placeholder="Selecciona un voluntario"
      {...form.getInputProps("volunteer")}
      data={volunteersOptions()}
      nothingFound="No hay voluntarios disponibles"
    />
  );
};

export default PositionsVolunteersModal;
