import { useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import PositionsFormModal from "../PositionsFormModal";

interface PositionsMapProps {
  activeMode: any;
  filterData: () => any[];
  handleMarkerClick: (position: any) => void;
}

const PositionsMap = (props: PositionsMapProps) => {
  const { filterData, handleMarkerClick, activeMode = 0 } = props;

  return (
    <MapContainer
      center={[38.267922, -0.689126]}
      zoom={13}
      style={{ zIndex: 0, height: "400px" }}
      scrollWheelZoom={true}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {filterData().map((position: any) => {
        if (position.lat && position.long) {
          return (
            <Marker
              eventHandlers={{
                click: () => handleMarkerClick(position),
              }}
              position={[position.lat, position.long]}
            />
          );
        }
      })}
      <MapEventsComponent activeMode={activeMode} />
    </MapContainer>
  );
};

interface MapEventsComponentProps {
  activeMode: number;
}

const MapEventsComponent = (props: MapEventsComponentProps) => {
  const { activeMode } = props;

  const [positionsModal, setPositionsModal] = useState(false);
  const [selectedLatLong, setSelectedLatLong] = useState<any>(null);

  useMapEvents({
    click(e) {
      if (activeMode === 0) {
        return;
      } else if (activeMode === 1) {
        setPositionsModal(true);
        setSelectedLatLong(e.latlng);
      } else if (activeMode === 2) {
        return;
      }
    },
  });
  return (
    <>
      <PositionsFormModal
        opened={positionsModal}
        setOpened={setPositionsModal}
        selectedLatLong={selectedLatLong}
        setSelectedLatLong={setSelectedLatLong}
      />
    </>
  );
};

export default PositionsMap;
