import MainLayout from "../../layouts/MainLayout";
import { ActionIcon, Box, Group } from "@mantine/core";
import { IconFilter, IconPencil, IconPlus } from "@tabler/icons";
import { useEffect, useState } from "react";
import PositionsFormModal from "./components/PositionsFormModal";
import SWRrender from "../../components/SWRrender";
import useSWR from "swr";
import PositionsFilterDrawer from "./components/PositionsFilterDrawer";
import PositionsMap from "./components/PositionsMap";
import PositionsTable from "./components/PositionsTable";
import PositionsVolunteersModal from "./components/PositionsVolunteersModal";

const Positions = () => {
  return (
    <MainLayout>
      <SWRrender>
        <PositionsList />
      </SWRrender>
    </MainLayout>
  );
};
const PositionsList = () => {
  const [activeMode, setActiveMode] = useState(0);
  const [filters, setFilters] = useState<any>(null);

  const {
    data: { positions },
  } = useSWR("/positions", { suspense: true });

  const handleModeChange = (mode: number) => {
    if (mode === activeMode) {
      setActiveMode(0);
    } else {
      setActiveMode(mode);
    }
  };

  const [filtersModal, setFiltersModal] = useState(false);
  const [positionsModal, setPositionsModal] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState<any>(null);

  const [volunteersModal, setVolunteersModal] = useState(false);

  useEffect(() => {
    if (!positionsModal && !volunteersModal) {
      setSelectedPosition(null);
    }
  }, [positionsModal, volunteersModal]);

  const filterData = () => {
    if (
      !filters ||
      (filters.zones.length === 0 &&
        filters.types.length === 0 &&
        filters.volunteers.length === 0)
    ) {
      return positions;
    }

    return positions.filter((position: any) => {
      if (
        filters.zones.length > 0 &&
        !filters.zones.includes(String(position.zone.id))
      ) {
        return false;
      }

      if (filters.types.length > 0 && !filters.types.includes(position.type)) {
        return false;
      }

      if (
        filters.volunteers.length > 0 &&
        position.volunteers.filter((volunteer: any) =>
          filters.volunteers.includes(String(volunteer.id))
        ).length === 0
      ) {
        return false;
      }

      return true;
    });
  };

  const handleMarkerClick = (position: any) => {
    if (activeMode === 2) {
      setSelectedPosition(position);
      setPositionsModal(true);
    }

    if (activeMode === 0) {
      setSelectedPosition(position);
      setVolunteersModal(true);
    }
  };

  return (
    <>
      <Group mb="sm" position="apart">
        <Group>
          <ActionIcon
            variant={activeMode == 1 ? "filled" : "outline"}
            onClick={() => handleModeChange(1)}
            color="blue"
          >
            <IconPlus size={16} />
          </ActionIcon>
          <ActionIcon
            variant={activeMode == 2 ? "filled" : "outline"}
            onClick={() => handleModeChange(2)}
            color="blue"
          >
            <IconPencil size={16} />
          </ActionIcon>
        </Group>

        <ActionIcon
          color="blue"
          variant="outline"
          onClick={() => setFiltersModal(true)}
        >
          <IconFilter size={16} />
        </ActionIcon>
      </Group>

      <PositionsMap
        filterData={filterData}
        activeMode={activeMode}
        handleMarkerClick={handleMarkerClick}
      />

      <Box mt="md">
        <PositionsTable
          filterData={filterData}
          activeMode={activeMode}
          setPositionsModal={setPositionsModal}
          setSelectedPosition={setSelectedPosition}
          setVolunteersModal={setVolunteersModal}
        />
      </Box>

      <PositionsFilterDrawer
        opened={filtersModal}
        setOpened={setFiltersModal}
        filters={filters}
        setFilters={setFilters}
      />

      <PositionsFormModal
        opened={positionsModal}
        setOpened={setPositionsModal}
        position={selectedPosition}
      />

      <PositionsVolunteersModal
        opened={volunteersModal}
        setOpened={setVolunteersModal}
        position={selectedPosition}
      />
    </>
  );
};

export default Positions;
