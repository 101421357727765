import { Button, Modal, NumberInput, Select, TextInput } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import useSWR, { mutate } from "swr";
import SWRrender from "../../../../components/SWRrender";
import * as Yup from "yup";
import dayjs from "dayjs";
import api from "../../../../api";
import { useEffect } from "react";
import { showSuccessToast } from "../../../../utils/toasts";
import { handleResponseError } from "../../../../utils/error";

interface PositionsFormModalProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  selectedLatLong?: any;
  setSelectedLatLong?: (selectedLatLong: any) => void;
  position?: any;
}
const PositionsFormModal = (props: PositionsFormModalProps) => {
  const { opened, setOpened, position } = props;

  const form = useForm({
    initialValues: {
      code: "",
      name: "",
      type: "",
      annotations: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      volunteers_needed: null,
    },
  });

  useEffect(() => {
    if (opened && !position && props.selectedLatLong) {
      form.setFieldValue("lat", props.selectedLatLong.lat);
      form.setFieldValue("long", props.selectedLatLong.lng);
    }

    if (opened && position) {
      form.setValues({
        ...position,
        zone_id: String(position.zone.id),
        startDate: position.start ? dayjs(position.start).toDate() : "",
        startTime: position.start ? dayjs(position.start).toDate() : "",
        endDate: position.end ? dayjs(position.end).toDate() : "",
        endTime: position.end ? dayjs(position.end).toDate() : "",
      });
    }

    if (!opened) {
      form.reset();
    }
  }, [opened]);

  const create = async (values: any) => {
    api
      .post("/positions", prepareDates(values))
      .then((data) => {
        if (data.data.ok) {
          setOpened(false);
          mutate("/positions");
          showSuccessToast({
            title: "Puesto creado",
            message: "El puesto ha sido creado correctamente",
          });
        } else {
          handleResponseError(data);
        }
      })
      .catch((err) => {
        handleResponseError(err);
      });
  };

  const update = async (values: any) => {
    api
      .put(`/positions/${position.id}`, prepareDates(values))
      .then(({ data }) => {
        if (data.ok) {
          setOpened(false);
          mutate("/positions");
          showSuccessToast({
            title: "Puesto actualizado",
            message: "El puesto ha sido actualizado correctamente",
          });
        }
      })
      .catch((err) => {
        handleResponseError(err);
      });
  };

  return (
    <Modal
      title={position ? "Editar puesto" : "Nuevo puesto"}
      opened={opened}
      onClose={() => setOpened(false)}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          position ? update(values) : create(values);
        })}
      >
        <TextInput
          label="Código del puesto"
          placeholder="Código del puesto"
          required
          {...form.getInputProps("code")}
        />

        <SWRrender>
          <ZoneSelect form={form} />
        </SWRrender>

        <Select
          label="Tipo de puesto"
          placeholder="Tipo de puesto"
          required
          {...form.getInputProps("type")}
          mt="sm"
          data={[
            { label: "Tráfico", value: "traffic" },
            { label: "Seguridad", value: "security" },
            { label: "Avituallamientos", value: "refreshments" },
            { label: "Cronometraje", value: "timing" },
          ]}
        />

        <TextInput
          label="Nombre del puesto"
          placeholder="Nombre del puesto"
          required
          {...form.getInputProps("name")}
          mt="sm"
        />

        <TextInput
          label="Observaciones"
          placeholder="Observaciones"
          {...form.getInputProps("annotations")}
          mt="sm"
        />

        <DatePicker
          label="Fecha de inicio"
          placeholder="Fecha de inicio"
          {...form.getInputProps("startDate")}
          mt="sm"
        />
        {form.values.startDate && (
          <TimeInput
            label="Hora de inicio"
            placeholder="Hora de inicio"
            {...form.getInputProps("startTime")}
            mt="sm"
          />
        )}

        <DatePicker
          label="Fecha de fin"
          placeholder="Fecha de fin"
          {...form.getInputProps("endDate")}
          mt="sm"
        />
        {form.values.endDate && (
          <TimeInput
            label="Hora de fin"
            placeholder="Hora de fin"
            {...form.getInputProps("endTime")}
            mt="sm"
          />
        )}

        <NumberInput
          label="Voluntarios necesarios"
          placeholder="Voluntarios necesarios"
          {...form.getInputProps("volunteers_needed")}
          mt="sm"
        />

        <Button fullWidth type="submit" variant="filled" mt="sm">
          {position ? "Actualizar" : "Crear"} puesto
        </Button>
      </form>
    </Modal>
  );
};

const ZoneSelect = (props: any) => {
  const { form } = props;

  const { data } = useSWR("/zones", { suspense: true });

  return (
    <Select
      label="Sector"
      placeholder="Sector"
      required
      {...form.getInputProps("zone_id")}
      nothingFound="No se han encontrado sectores"
      mt="sm"
      data={data.zones.map((zone: any) => ({
        label: zone.name,
        value: String(zone.id),
      }))}
    />
  );
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required("El código del puesto es obligatorio"),
  zone_id: Yup.string().required("El sector es obligatorio"),
  type: Yup.string()
    .required("El tipo de puesto es obligatorio")
    .oneOf(
      ["traffic", "security", "refreshments", "timing"],
      "El tipo de puesto no es válido"
    ),
  name: Yup.string().required("El nombre del puesto es obligatorio"),
  annotations: Yup.string().notRequired().nullable(),
  startDate: Yup.date().nullable().notRequired(),
  startTime: Yup.date().nullable().notRequired(),
  endDate: Yup.date().nullable().notRequired(),
  endTime: Yup.date().nullable().notRequired(),
  volunteers_needed: Yup.number().nullable().notRequired(),
});

const prepareDates = (values: any) => {
  let result = values;
  if (values.startDate && values.startTime) {
    result.start = new Date(
      dayjs(values.startDate).format("YYYY-MM-DD") +
        " " +
        dayjs(values.startTime).format("HH:mm:ss")
    ).toISOString();
  } else if (values.startDate) {
    result.start = new Date(
      dayjs(values.startDate).format("YYYY-MM-DD")
    ).toISOString();
  } else if (values.endDate && values.endTime) {
    result.end = new Date(
      dayjs(values.endDate).format("YYYY-MM-DD") +
        " " +
        dayjs(values.endTime).format("HH:mm:ss")
    ).toISOString();
  } else if (values.endDate) {
    result.end = new Date(
      dayjs(values.endDate).format("YYYY-MM-DD")
    ).toISOString();
  }

  return result;
};

export { prepareDates as preparePositionDates };
export default PositionsFormModal;
