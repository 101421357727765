import MainLayout from "../../layouts/MainLayout";
import { TableCard } from "@3t/utils";
import { IconPlus } from "@tabler/icons";
import SWRrender from "../../components/SWRrender";
import useSWR from "swr";
import { useState } from "react";
import UsersModal from "./components/UsersModal";
const Users = () => {
  const { data } = useSWR("/users", { suspense: true });

  const columns = [
    {
      Header: "Nombre",
      dataIndex: "name",
      accessor: "name",
    },
    {
      Header: "Email",
      dataIndex: "email",
      accessor: "email",
    },
  ];

  const [usersModal, setUsersModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  return (
    <MainLayout>
      <SWRrender>
        <TableCard
          searchPlaceholder={"Buscar usuarios"}
          addButton={<IconPlus />}
          onAddButtonClick={() => setUsersModal(true)}
          onRowClick={(row) => {
            setSelectedUser(row);
            setUsersModal(true);
          }}
          title="Listado de usuarios"
          columns={columns}
          data={data.users}
        />
      </SWRrender>

      <UsersModal
        opened={usersModal}
        setOpened={setUsersModal}
        user={selectedUser}
        setUser={setSelectedUser}
      />
    </MainLayout>
  );
};

export default Users;
